<template>
  <div class="refer-afriend-container">
    <PageLayout>
      <template #page-name>
        <div class="copy-notification-wrapper">
          <div class="copy-notification" :class="{ show: showNotif == true }">
            <div id="img">
              <img
                src="@/assets/img/01-Menu/icon-checkmark-circle-white.svg"
                alt=""
              />
            </div>
            <div id="desc">Copied successfully</div>
          </div>
        </div>
        <div class="nav-title font-menu-large">
          <div
            class="nav-title-item"
            v-for="item in navList"
            :key="item.id"
            :class="{ active: item.id === currentPage }"
            @click="changePage(item.id)"
          >
            {{ item.title }}
          </div>
        </div>
      </template>
      <template #page-content>
        <div class="content">
          <div class="refer-title handle-word-wrap font-menu-large">
            Invite your friends and get FREE CLASSES
          </div>
          <div class="description line-word-overflow-wrap font-menu-medium">
            Would you like to get a free NihaoKids class for your child? It's easy — just refer a friend! The more friends you invite, the more free classes you will get.
          </div>
          <div class="step-box">
            <div class="refer-img">
              <img src="@/assets/img/05-Panda/panda-waving.svg" alt="" />
            </div>
            <div class="steps-one">
              <span class="speech-bubble-origin"
                ><img
                  :src="
                    require(`@/assets/img/01-Menu/speech-bubble-origin-left.svg`)
                  "
                  alt=""
              /></span>
              <span class="line-dot"></span>
              <span class="line-hide"></span>
              <span class="line"></span>
              <div class="title handle-word-wrap font-menu-large">
                Share your referral link
              </div>
              <p class="font-menu-medium">
                To share NihaoKids with your friend(s), copy the unique link below. This way we will know that you have referred them, and you'll be able to claim your free class.
              </p>
              <div
                class="share-by-link handle-word-wrap font-menu-medium"
                @click="copyLinkClipboard"
              >
                Copy the link
              </div>
            </div>
            <div class="steps-two">
              <span class="speech-bubble-origin"
                ><img
                  :src="
                    require(`@/assets/img/01-Menu/speech-bubble-origin-left.svg`)
                  "
                  alt=""
              /></span>
              <span class="line-dot"></span>
              <span class="line"></span>
              <div class="title handle-word-wrap font-menu-large">
                You and your friend will get a FREE class
              </div>
              <p class="font-menu-medium">
                Once your friend(s) sign up for a free class, we will receive a notification that you are eligible for a free class, and our course consultants will get in touch with you promptly.
              </p>
            </div>
            <div class="steps-three">
              <span class="speech-bubble-origin"
                ><img
                  :src="
                    require(`@/assets/img/01-Menu/speech-bubble-origin-left.svg`)
                  "
                  alt=""
              /></span>
              <span class="line-dot"></span>
              <span class="line-hide"></span>
              <span class="line"></span>
              <div class="title handle-word-wrap font-menu-large">
                Earn 5 FREE 1-on-1 classes
              </div>
              <p class="font-menu-medium">
                ...and there is more! If after a trial your friends choose to sign up and purchase 32 classes or more, you will get a bonus of extra 5 classes. You can check the status of your referrals in the table below.
              </p>
            </div>
          </div>
          <!-- :row-style="{height:'32px'}"  
              :header-row-style="{height:'50px'}"  
              :cell-style="{padding:'1px'}"> -->
          <div class="information font-menu-small">
            <el-table
              :data="tableData"
              style="width: 100%"
              row-class-name="row-style"
              cell-class-name="cell-style font-menu-medium"
              header-cell-class-name="header-cell-style font-menu-small"
            >
              <el-table-column prop="name" label="Name" width="140">
              </el-table-column>
              <el-table-column prop="joinDate" label="Join Date" width="140">
              </el-table-column>
              <el-table-column prop="trialDate" label="Trial Date" width="140">
              </el-table-column>
              <el-table-column
                prop="purchasedDate"
                label="Purchased Date"
                width="200"
              >
              </el-table-column>
              <el-table-column
                prop="purchasedClasses"
                label="# Purchased classes"
                width="200"
              >
              </el-table-column>
              <el-table-column prop="bouns" label="Your Bonus">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </template>
    </PageLayout>
  </div>
</template>

<script>
import PageLayout from "@/components/Page/Layout/PageLayout.vue";

export default {
  name: "ReferAFriend",
  components: {
    PageLayout,
    // UpcomingClasses,
  },
  data() {
    return {
      currentPage: 1,
      navList: [
        {
          title: "Refer a Friend",
          id: 1,
        },
      ],
      tableData: [
        {
          name: "Emma Trigo",
          joinDate: "2021-10-01",
          trialDate: "2021-10-02",
          purchasedDate: "2021-10-01",
          purchasedClasses: 32,
          bouns: "5 Free Classes",
        },
      ],
      showNotif: false,
    };
  },
  created() {
    const { userSetting } = JSON.parse(localStorage.getItem("userData"));
    // this.tableData[0] = {
    //   name:userSetting.nickname
    // }

  },
  methods: {
    changePage(index) {
      this.currentPage = index;
    },

    async copyLinkClipboard() {
      try {
        await navigator.clipboard.writeText("<referer URL to define>");
        this.showNotif = true;
        setTimeout(() => {
          this.showNotif = false;
        }, 3000);
      } catch ($e) {
        console.log("catch copyLinkClipboard", $e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.copy-notification-wrapper {
  width: 100%;
  display: inline-block;
  position: absolute;
  top: 5%;
}

/* -------------------------------------------------------------------------- */
/*                      Animations for Toast notification                     */
/* -------------------------------------------------------------------------- */
.copy-notification {
  visibility: hidden;
  max-width: 50px;
  height: 50px;
  /*margin-left: -125px;*/
  margin: auto;
  color: #fff;
  text-align: center;
  border-radius: 2px;

  // position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 30px;
  font-size: 17px;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 10%;
  background: #32a836;
  width: 40%;
  border-radius: 30px;
  padding: 10px 30px;
  font-size: 1rem;
  color: #ffffff;
}
.copy-notification #img {
  width: 50px;
  height: 50px;

  float: left;

  padding-top: 12px;

  box-sizing: border-box;

  color: #fff;
}
.copy-notification #desc {
  color: #fff;

  padding: 16px;

  overflow: hidden;
  white-space: nowrap;
}

.copy-notification.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, expand 0.5s 0.5s, stay 1.5s 1s, shrink 0.5s 2s,
    fadeout 0.5s 2.5s;
  animation: fadein 0.5s, expand 0.5s 0.5s, stay 1.5s 1s, shrink 0.5s 2s,
    fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes expand {
  from {
    min-width: 50px;
  }
  to {
    min-width: 350px;
  }
}

@keyframes expand {
  from {
    min-width: 50px;
  }
  to {
    min-width: 350px;
  }
}
@-webkit-keyframes stay {
  from {
    min-width: 350px;
  }
  to {
    min-width: 350px;
  }
}

@keyframes stay {
  from {
    min-width: 350px;
  }
  to {
    min-width: 350px;
  }
}
@-webkit-keyframes shrink {
  from {
    min-width: 350px;
  }
  to {
    min-width: 50px;
  }
}

@keyframes shrink {
  from {
    min-width: 350px;
  }
  to {
    min-width: 50px;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 60px;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 60px;
    opacity: 0;
  }
}
</style>

<style lang="scss">
.information {
  margin-top: 4%;
  padding-bottom: 10%;
}
</style>
